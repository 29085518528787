import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import { AxiosResponse } from "axios";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import { FilterData } from "@/core/types/gws-agent/dashboard/FilterData";
import { formatInternationalDate } from "@/core/helpers/date-format";

export const useDashboardVFSStore = defineStore({
  id: "dashboardVFSStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFilterData: {
        date: [new Date(), new Date()],
      } as FilterData,
      stateDisplayData: {
        PRE_SECURITY: 0,
        PRE_SECURITY_PASSED: 0,
        PRE_SECURITY_BLACKLIST: 0,
      },
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    displayData(state) {
      return state.stateDisplayData;
    },
  },
  actions: {
    async getData(isPageRefresh = true) {
      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const [startDate, endDate] = this.stateFilterData.date;
        const params = {
          start_date: formatInternationalDate(startDate.toString()),
          end_date: formatInternationalDate(endDate.toString()),
        };

        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `dashboards/vfs?${new URLSearchParams(params).toString()}`
        );

        const { vfs } = response.data.data;
        this.stateDisplayData = vfs;
      } catch (error) {
        console.log(error);
      }
      this.stateIsLoading = false;
    },
  },
});
