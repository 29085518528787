import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-1d0b3182")
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "d-flex gap-2"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 1,
  class: "d-flex gap-2 justify-content-start"
}
const _hoisted_6 = ["onClick"]
_popScopeId()


import { useCpmiListStore } from "@/store/pinia/gws-agent/cpmi/useCpmiListStore";
import { ListItem } from "@/core/types/gws-agent/cpmi/ListItem";
import { useCpmiFormStore } from "@/store/pinia/gws-agent/cpmi/useCpmiFormStore";
import { simpleFormatDate } from "@/core/helpers/date-format";
import { CPMIStatusEnum } from "@/store/enums/StatusEnums";
//props

export default _defineComponent({
  props: {
  listData: {
    required: true,
    type: Array,
  },
  page: {
    required: true,
    type: Number,
    default: 1,
  },
},
  emits: [
  "delete-data",
  "view-detail",
  "process",
  "reject",
  "view-detail-modal",
],
  setup(__props, { emit: emits }) {

const props = __props

const listStore = useCpmiListStore();
const formStore = useCpmiFormStore();


const statusTracker = (status: string) => {
  switch (status) {
    case CPMIStatusEnum.PRE_SECURITY_BLACKLIST:
      return "Blacklist";
    case CPMIStatusEnum.PRE_SECURITY:
      return "Daftar Tunggu";
    case CPMIStatusEnum.PRE_SECURITY_PASSED:
      return "Passed";
    case CPMIStatusEnum.REGISTRATION_REJECTED:
      return "Ditolak";
    case CPMIStatusEnum.PRE_REGISTRATION_REJECTED:
      return "Ditolak";
    default:
      return "Menunggu Proses";
  }
};
const handleProcess = (item: ListItem) => {
  emits("process", item);
};
const handleShowDetailModal = (item: ListItem) => {
  emits("view-detail-modal", item);
};

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_unref(listStore).loading)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512)), [
        [_directive_loading, _unref(listStore).loading]
      ])
    : _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        key: 1,
        data: props.listData,
        "empty-text": 'No Data',
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "no",
            label: "No.",
            "min-width": "20",
            type: "index",
            align: "center"
          }),
          _createVNode(_component_el_table_column, {
            prop: "first_name",
            label: "Nama",
            "min-width": "100",
            align: "left"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.first_name) + " " + _toDisplayString(scope.row.middle_name) + " " + _toDisplayString(scope.row.last_name), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "nik",
            label: "No.Passport"
          }),
          _createVNode(_component_el_table_column, {
            prop: "created_at",
            label: "Dibuat"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(_unref(simpleFormatDate)(scope.row.created_at)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "status",
            label: "Status Terakhir"
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_tag, {
                type: "",
                "disable-transitions": ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(statusTracker(scope.row.status)), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "action",
            label: "Aksi",
            fixed: "right",
            align: "left"
          }, {
            default: _withCtx((scope) => [
              (scope.row.status === _unref(CPMIStatusEnum).PRE_SECURITY)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("button", {
                      class: "btn-next px-4 py-2",
                      onClick: ($event: any) => (handleProcess(scope.row))
                    }, " Proses ", 8, _hoisted_3),
                    _createElementVNode("button", {
                      class: "btn-next px-4 py-2",
                      onClick: ($event: any) => (handleShowDetailModal(scope.row))
                    }, " Detail ", 8, _hoisted_4)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("button", {
                      class: "btn-next px-4 py-2",
                      onClick: ($event: any) => (handleShowDetailModal(scope.row))
                    }, " Detail ", 8, _hoisted_6)
                  ]))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])), [
        [_directive_loading, _unref(listStore).loading]
      ])
}
}

})