<template>
  <div class="card rounded-xl m-4" :style="{ 'background-color': secondColor }">
    <div class="d-flex flex-row align-items-center p-5 gap-3">
      <div
        class="rounded-circle-container"
        :style="{ 'background-color': color }"
      >
        <i
          class="bi bi-bar-chart-fill h4 bg-white rounded-1 p-1 m-0"
          :style="{ color: color }"
        ></i>
      </div>

      <div class="p-0">
        <h4>
          <span>{{ value }}</span> <span>{{ desc }}</span>
        </h4>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "CardColorVertical",
  props: {
    value: Number,
    desc: String,
    color: String,
    secondColor: String,
  },
  components: {},
});
</script>
<style lang="scss" scoped>
.rounded-circle-container {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
